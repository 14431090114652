$duration: 1.5s;

.loading_line_wrapper {
    transform: rotate(90deg) translate(34px, 32px);
    height: 2px;
    width: 65px;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
}

.loading_line {
    position: relative;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    transform-origin: 100% 0%;
    animation: kf_loading_line $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;

    .loading_line_inner {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        background: white;
        transform-origin: 0% 0%;
    }
}
.loading_line_inner--1 {
    opacity: 0; //change for inner line opacity, currently 0/hidden
    animation: kf_loading_line_inner--1 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner--2 {
    opacity: 1;
    animation: kf_loading_line_inner--2 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    background-color: red;
    transform-origin: 0% 0%;
}

@keyframes kf_loading_line {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}

@keyframes kf_loading_line_inner--1 {
    0% {
        transform: scaleX(0);
    }
    25% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes kf_loading_line_inner--2 {
    0% {
        transform: scaleX(0);
    }
    25% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(1);
    }
}
