.brainstationImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  &:hover {
    .brainstationFullImage {
      display: flex;
      opacity: 1;
    }
    .brainstationRedImage {
      display: none;
      opacity: 0;
    }
  }

  .brainstationRedImage {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.6s ease-in-out;
  }

  .brainstationFullImage {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }
}
