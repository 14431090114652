button {
	letter-spacing: 1px;
	background: none;
	color: white;
	position: relative;
	outline: none;
	margin-top: 20%;
	border: none;
	height: 47px;
	width: 134px;
	font-weight: bold;
	border-radius: 4px;
	font-size: 16px;
	letter-spacing: 2px;
	z-index: 2;
	transition: 0.01s 0.23s ease-out all;
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 4px;
		height: 100%;
		width: 100%;
		background: #ff4d5a;
		z-index: -1;
		transition: 0.3s ease-in all;
	}
	&:hover {
		border: 2px solid #ff4d5a;
		cursor: pointer;
		color: transparent;
		&:before {
			left: 100%;
			width: 25%;
		}
		&:after {
			left: 100%;
			width: 70%;
		}
		.icon-right.after:after {
			left: -40px;
			color: white;
			transition: 0.2s 0.2s ease all;
		}
		.icon-right.after:before {
			left: -67px;
			top: 14px;
			opacity: 0.2;
			color: white;
		}
		.icon-right-more.after:after {
			left: -40px;
			color: white;
			transition: 0.2s 0.2s ease all;
		}
		.icon-right-more.after:before {
			left: -67px;
			top: 14px;
			opacity: 0.2;
			color: white;
		}
	}
}

.icon-right-more {
	position: absolute;
	top: 0;
	right: 0;
	&:after {
		content: "\2192";
		font-size: 20px;
		font-weight: 600;
		display: inline-block;
		position: relative;
		top: 23px;
		transform: translate3D(-10px, -50%, 0);
	}
	&.after:after {
		left: -250px;
		color: black;
		transition: 0.15s 0.25s ease left, 0.5s 0.05s ease color;
	}
	&.after:before {
		content: "More";
		position: absolute;
		left: -230px;
		top: 10px;
		opacity: 0;
		transition: 0.2s ease-in all;
	}
}

.icon-right {
	position: absolute;
	top: 0;
	right: 0;
	&:after {
		content: "\2192";
		font-size: 20px;
		font-weight: 600;
		display: inline-block;
		position: relative;
		top: 23px;
		transform: translate3D(-10px, -50%, 0);
	}
	&.after:after {
		left: -250px;
		color: black;
		transition: 0.15s 0.25s ease left, 0.5s 0.05s ease color;
	}
	&.after:before {
		content: "Explore";
		position: absolute;
		left: -230px;
		top: 10px;
		opacity: 0;
		transition: 0.2s ease-in all;
	}
}

.signature {
	position: fixed;
	font-family: sans-serif;
	font-weight: 100;
	bottom: 10px;
	left: 0;
	letter-spacing: 4px;
	font-size: 10px;
	width: 100vw;
	text-align: center;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
}
